<template>
  <div>
    <q-form ref="editForm">
      <c-card title="LBLBASEINFO" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              v-show="editable"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="restFacility"
              :mappingType="mappingType"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveInfo"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-text
              :required="true"
              :editable="editable"
              label="위치"
              name="locationName"
              v-model="restFacility.locationName">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-text
              :required="true"
              :editable="editable"
              label="구분"
              name="restFacilitiesName"
              v-model="restFacility.restFacilitiesName">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <c-text
              :editable="editable"
              type="number"
              label="휴게시설 개수"
              name="restFacilitiesCount"
              v-model="restFacility.restFacilitiesCount">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <c-text
              :editable="editable"
              label="부서"
              name="deptName"
              v-model="restFacility.deptName">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <c-plant
              :editable="editable"
              type="edit"
              label="사업장"
              name="plantCd"
              v-model="restFacility.plantCd">
            </c-plant>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <c-select
              :editable="editable"
              :comboItems="useFlagItems"
              type="edit"
              itemText="codeName"
              itemValue="code"
              name="useFlag"
              label="LBLUSEFLAG"
              v-model="restFacility.useFlag"
            ></c-select>
          </div>
        </template>
      </c-card>
    </q-form>
    <c-table
      ref="table"
      title="비품 현황"
      class="q-mt-sm"
      :columns="grid.columns"
      :data="restFacility.fixturesAmounts"
      :gridHeight="gridHeight"
      :filtering="false"
      :isExcelDown="false"
      :isFullScreen="false"
      :columnSetting="false"
      :editable="editable"
      selection="multiple"
      rowKey="sopRestFacilitiesFixturesAmountId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLEXCEPT" icon="delete" @btnClicked="deleteFixtures" />
          <c-btn v-if="editable" label="LBLADD" icon="add" @btnClicked="addFixtures" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'restFacilitiesDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopRestFacilitiesId: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      restFacility: {
        sopRestFacilitiesId: '',  // 휴게 시설 마스터 일련번호
        plantCd: '',  // 사업장 코드
        locationName: '',  // 위치
        restFacilitiesName: '',  // 휴게 시설 구분
        deptName: '',  // 부서코드
        restFacilitiesCount: '', // 휴게 시설 개수
        useFlag: 'Y',  // 사용여부
        fixturesAmounts: [], // 휴게시설 별 비품 현황
        deleteFixturesAmounts: [], // 휴게시설 별 비품 현황
      },
      grid: {
        columns: [
          {
            name: 'fixturesName',
            field: 'fixturesName',
            label: '비품명',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
          {
            name: 'amount',
            field: 'amount',
            label: '규제항목',
            align: 'right',
            sortable: true,
            style: 'width:100px',
            type: 'number'
          },
        ],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      isSave: false,
      detailUrl: '',
      saveUrl: '',
      mappingType: 'PUT',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.sopRestFacilitiesId);
    },
    gridHeight() {
      let height = this.contentHeight - 300;
      if (!height || height < 400) {
        height = 400
      }
      return String(height) + 'px';
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.rtf.restFacilities.get.url
      this.saveUrl = transactionConfig.sop.rtf.restFacilities.update.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopRestFacilitiesId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.restFacility, _result.data)
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        // nothing...
      }
    },
    deleteFixtures() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.restFacility.deleteFixturesAmounts) this.restFacility.deleteFixturesAmounts = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.restFacility.deleteFixturesAmounts, { sopRestFacilitiesFixturesId: item.sopRestFacilitiesFixturesId }) === -1) {
              this.restFacility.deleteFixturesAmounts.push(item);
          }
          this.restFacility.fixturesAmounts = this.$_.reject(this.restFacility.fixturesAmounts, item);
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
    addFixtures() {
      this.popupOptions.title = '비품 검색';
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'./restFacilitiesFixturesPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeFixturesPopup;
    },
    closeFixturesPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.restFacility.fixturesAmounts, { sopRestFacilitiesFixturesId: item.sopRestFacilitiesFixturesId }) === -1) {
            this.restFacility.fixturesAmounts.push({
              sopRestFacilitiesFixturesAmountId: uid(),  // 휴게 시설 별 비품 수량 관리 일련번호
              sopRestFacilitiesId: this.popupParam.sopRestFacilitiesId,  // 휴게 시설 마스터 일련번호
              sopRestFacilitiesFixturesId: item.sopRestFacilitiesFixturesId,  // 휴게 시설 비품 일련번호
              fixturesName: item.fixturesName,  // 비품명
              amount: '',  // 수량
              regUserId: this.$store.getters.user.userId,  // 등록자
              editFlag: 'C',
            })
          }
        })
        console.log(this.restFacility.fixturesAmounts)
      }
    },
    saveInfo() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sop.rtf.restFacilities.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.rtf.restFacilities.insert.url
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.restFacility.regUserId = this.$store.getters.user.userId;
              this.restFacility.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'sopRestFacilitiesId', result.data)
      this.getDetail();
    },
  }
};
</script>